@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

a {
  text-decoration: none !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  background: #ffffff !important;
}

html {
  --scrollbarBG: rgb(206, 206, 206);
  --thumbBG: #073c7c;
  --Primary: #073c7c;
  --Active : #29a300;
  --InActive :#d70000;
  --Black: #000000;
  --White: #ffffff;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}


.form-check-input {
  border: 1px solid #6c6c6c !important;
}
.form-check-input:focus {
  box-shadow: none !important;
}

label.form-label {
  color: #575757 !important;
  font-size: 14px !important;
  margin-bottom: 6px !important;
  font-weight: 600 !important;
 }
 
 label.form-label span{
   color: red !important;
 }

  .searchCard{
    padding: 0px 14px 14px !important;
    border: none !important;
    border-radius: 8px !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .searchCard .searchLabel{
    background: linear-gradient(145deg, #1267cf, #073c7c) !important;
    width: max-content !important;
    color: var(--White) !important;
    font-weight: 500 !important;
    text-align: center !important;
    margin: auto auto 12px auto !important;
    border-radius: 0px 0px 8px 8px !important;
    padding: 2px 8px !important;
    font-size: 13px !important;
  }

  /* ------ Form Inputs ID's ------- */
  #fName,
  #lName,
  #empId,
  #venue,
  #password,
  #mobile,
  #workEmail,
  #empDesgn,
  #experience,
  #resourceType,
  #gender,
  #department,
  #skills{
    scroll-margin-top: 50px !important;
  }

  @media (max-width: 820px) {
     /* input[type="date"]:before {
        content: 'dd-mm-yyyy' !important;
    } */

     input[type="date"]:focus:before {
        content: '' !important;
    }

     input[type="date"] {
        display: flex !important;
    }
}

label.form-label span {
  color: red !important;
}

.searchCard {
  padding: 0px 14px 14px !important;
  border: none !important;
  border-radius: 8px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.searchCard .searchLabel {
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  width: max-content !important;
  color: var(--White) !important;
  font-weight: 500 !important;
  text-align: center !important;
  margin: auto auto 12px auto !important;
  border-radius: 0px 0px 8px 8px !important;
  padding: 2px 8px !important;
  font-size: 13px !important;
}

/* ------ Form Inputs ID's ------- */
#fName,
#lName,
#empId,
#venue,
#password,
#mobile,
#workEmail,
#empDesgn,
#experience,
#resourceType,
#gender,
#department,
#skills {
  scroll-margin-top: 50px !important;
}

@media (max-width: 640px) {
  .form-layout {
    width: 100%;
  }

  .deleteCard {
    width: 100% !important ;
  }

  /* input[type="date"]:before {
    content: "dd-mm-yyyy" !important;
  } */

  input[type="date"]:focus:before {
    content: "" !important;
  }

  input[type="date"] {
    display: flex !important;
  }

  .date-flex{
    flex-direction: column;
    align-items: center !important;
  }

}

.PageNotFound {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PageNotFound h1 {
  font-weight: 600;
}

/* ----------- Global Buttons & Err Msg Declaration ------------ */
.errMsg {
  color: red !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin: 3px 0px 0px 5px !important;
  text-align: start !important;
}

.InputLabel {
  color: #6f6f6f;
  font-weight: 500;
  margin: 0;
}

.loginBtn {
  color: #ffffff !important;
  background: #000000 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border: none !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1) !important;
  outline: none !important;
}

.primaryBtn {
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  padding: 5px 14px !important;
  border-radius: 8px !important;
  border: none !important;
  transition: 0.2s !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1) !important;
  outline: none !important;
}

  
  .primaryBtn:active {
    top: 2px !important;
  }

.primaryBtn:hover {
  color: var(--Primary) !important;
  background: var(--White) !important;
  border: none !important;
  border-radius: 8px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.secondaryBtn {
  color: #000000 !important;
  background: transparent !important;
  border: 1px solid var(--Primary) !important;
  border-radius: 6px !important;
  padding: 5px 11px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
  transition: all 0.3s ease !important;
  position: relative !important;
}

.secondaryBtn:hover {
  color: var(--White) !important;
  background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  border: 1px solid transparent !important;
  border-radius: 6px !important;
}
.secondaryBtn:active {
  top: 2px;
}

.archiveBtn{
  width: max-content !important;
  border: 1px solid var(--Primary) !important;
  padding: 4px 12px !important;
  transition: 0.2s ease-in-out !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}


/* 
.archiveBtn:hover{
  transform: translateY(-3px) !important;
} */

.activeArchiveBtn{
  background: var(--Primary) !important;
  color: white !important;
}
.nonActiveArchiveBtn{
  background: white !important;
  color: var(--Primary) !important;
}

/* -------------------- Reject Btn --------------------------*/
 .rejectBtn {
        color: red !important;
        background: transparent !important;
        padding: 3px 9px !important;
        border-radius: 8px !important;
        border: 1px solid red !important;
        transition: 0.2s !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        cursor: pointer !important;
        transition: all 0.3s ease !important;
        position: relative !important;
        /* box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
        7px 7px 20px 0px rgba(0,0,0,.1),
        4px 4px 5px 0px rgba(0,0,0,.1) !important; */
        outline: none !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }

        .rejectBtn:hover{
          color: var(--White) !important;
          background: linear-gradient(145deg, #ff0303, var(--InActive)) !important;
          border: 1px solid transparent !important;
          border-radius:8px !important;
          box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
            7px 7px 20px 0px rgba(0,0,0,.1),
            4px 4px 5px 0px rgba(0,0,0,.1) !important;
        }

        .rejectBtn:active {
          top: 2px !important;
        }

 /* ---------------------------- Approve Btn ---------------------------*/
        
        
  .approveBtn{
      color: var(--White) !important;
      background: linear-gradient(145deg, #2ab300, #29a300) !important;
      padding: 5px 10px;
      border-radius: 8px !important;
      border: none !important;
      transition: 0.2s !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      cursor: pointer !important;
      transition: all 0.3s ease !important;
      position: relative !important;
      box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
      7px 7px 20px 0px rgba(0,0,0,.1),
      4px 4px 5px 0px rgba(0,0,0,.1) !important;
      outline: none !important;
      font-size: 14px !important;
  }

  .approveBtn:active {
    top: 2px !important;
  }

  .approveBtn:hover{
    color: var(--Active) !important;
    background: var(--White) !important;
    border: none !important;
    border-radius:8px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

/* --------------------------- Logout Btn ------------------------- */

  .LogoutBtn{
    color: var(--White) !important;
    background: transparent !important;
    width: 100% !important;
    border: none !important;
    border-radius:6px !important;
    transition: 0.3s !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    transition: 0.3s !important;
  }

  .LogoutBtn:hover{
    color: var(--White) !important;
    background: transparent !important;
    width: 100% !important;
    border: none !important;
    border-radius:6px !important;
  transform: translateX(-10px) !important;
  }

.CancelBtn {
  color: #000000 !important;
  border: 1px solid #f7ce40 !important;
  background: transparent !important;
  border-radius: 6px !important;
  transition: 0.3s !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px 11px !important;
}

.accordionNav {
  border: 1px solid var(--FoxBlack) !important;
  color: var(--FoxBlack) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: transparent !important;
}

.accordionNavActive {
  border: 1px solid transparent !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  background: var(--FoxBlack) !important;
}

.pagination {
  width: 100% !important;
}

.pagination .prev,
.pagination .prev:focus,
.pagination .next,
.pagination .next:focus {
  background: transparent !important;
  border: none !important;
  color: var(--FoxBlack) !important;
  transition: 0.3s !important;
}

.pagination .prev:hover {
  background: transparent;
  border: none;
  color: var(--FoxBlack);
  transform: translateX(-5px);
}

.pagination .next:hover {
  background: transparent;
  border: none;
  color: var(--Black);
  transform: translateX(5px);
}

.pagination .activePageButton {
  border: 1px solid var(--Black) !important;
  color: var(--Black) !important;
  background: var(--White) !important;
  transition: 0.1s !important;
  font-weight: 600;
}

.pagination .pageButton {
  border: 1px solid transparent !important;
  color: var(--Black) !important;
  background: transparent !important;
  transition: 0.1s !important;
}

.pagination select.form-select {
  border: 1px solid #6f6f6f;
  transition: 0.3s;
}

.modal .modal-content {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.modal .modal-content .modal-header {
  font-weight: 600;
}

.modal .modal-content .modal-header h5 {
  margin: 0px;
  font-weight: 500;
  color: var(--Black);
  display: flex;
  align-items: center;
}
.modal .modal-content .modal-body{
  padding: 25px 20px;  
}

.modal label {
  font-size: 15px !important;
  color: #575757;
  font-weight: 600 !important;
  margin: 0px 0px 4px !important;
}

.modal .modal-footer{
  background: #ffffff !important;
}

.profile h2 {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
}

.profile .card {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2) !important;
}

.profile .card div {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.profile .card h6 {
  margin: 0px;
}

.profile .card p {
  margin-left: 6px;
  margin-bottom: 0px;
}
/* --- Login CSS ----*/

.loginNew {
  height: 100vh;
}

.loginNew .left {
  background: url(./Assets/PMC_Office.png) center center no-repeat;
  background-size: cover;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.loginNew h2 {
  background: linear-gradient(180deg, #4c4c4c, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}

.loginNew .right .card {
  border: 0px;
  border-radius: 15px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: 100%;
}

.loginNew .right input {
  /* border: none none none none !important; */
  border-color: transparent transparent #363434 transparent !important;
  border-radius: 0px !important;
}

.loginNew .right .input-group-text {
  /* border: none none none none !important; */
  border-color: transparent transparent #363434 transparent !important;
  border-radius: 0px !important;
  padding: 4px 10px !important;
  background: transparent;
  margin-left: -2px;
}

.loginNew .right input:focus {
  outline: none;
  box-shadow: none;
}

.login .right{
  background: #000000;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 12px 12px 0px;
}

/* ------ Forgot Pass ------ */
.ForgotPass {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ForgotPass .card {
  border: none;
  border-radius: 12px;
  padding: 0px 20px 25px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ForgotPass .card h3 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 4px;
}

.ForgotPass .card h5 {
  font-weight: 600;
  text-align: center;
  margin-bottom: 0px;
}

.ForgotPass .card p {
  font-size: 14px;
  color: #575757;
  text-align: center;
  margin-bottom: 8px;
}

/* -------------------- Skeleton Loader CSS ------------------------- */
.loader {
  background-color: #ededed;
  height: 35px;
  border-radius: 2px;
  width: 100%;
}

.tr {
  border-bottom: 1px solid #ddd;
}

.td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

td .loader {
  background-color: #ededed;
  background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    )
    #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -40%;
  }
}

nav {
  position: fixed;
  left: 40vh;
  right: 0vh;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
  z-index: 1000;
}

nav h5 {
  color: black;
  font-weight: 600;
}
nav h6 {
  color: black;
  font-weight: 600;
}

nav ul {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

/* nav ul li {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px 10px;
  transition: 0.2s;
  display: inline-block;
  color: var(--yellow-brand-color);
}

nav ul li:hover {
  transform: translateY(-3px);
  color: white;
} */

@media (max-width: 992px) {
  .lg-nav {
    display: none;
  }

  .mobile-nav {
    position: fixed;
    --gap: 2rem;
    border-radius: 0px;
    inset: 0 0 0 20%;
    z-index: 1000;
    flex-direction: column;
    padding: 15% 2% 10% 0px;
    transform: translateX(100%);
    transition: 0.3s;
    list-style: none;
    margin: 0;
    background: var(--Primary);
    overflow-y: scroll;
  }

  .mobile-nav li {
    margin-bottom: 15px;
    color: yellow;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
  .mobile-nav a.nav-link {
    border: 1px solid var(--FoxBlack);
    color: var(--FoxBlack);
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 15px;
    font-weight: 500;
  }

  .mobile-nav a.nav-link.active {
    background: var(--FoxBlack);
    border: 1px solid transparent;
    color: #ffffff;
    font-weight: 500;
  }

  .mobile-nav[data-visible="true"] {
    transform: translateX(0%);
  }

  .mobile-nav-toggle {
    margin: 5px 0px 5px 0px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 30px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    /* border: 3px solid #fff; */
  }

  .mobile-nav-toggle .menu-btn__burger {
    width: 35px;
    height: 3px;
    background: var(--Primary);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
    z-index: 9999;
  }
  .mobile-nav-toggle .menu-btn__burger::before,
  .mobile-nav-toggle .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 36px;
    height: 3px;
    background: var(--Primary);
    border-radius: 5px;
    transition: all 0.5s ease-in-out;
  }

  .mobile-nav-toggle .menu-btn__burger::before {
    transform: translateY(-10px);
  }

  .mobile-nav-toggle .menu-btn__burger::after {
    transform: translateY(10px);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
    background: var(--White);
  }

  .mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
    background: var(--White);
  }
}

/* ------ SideBar-lg CSS for Large or Desktop Devices ------ */
@media (min-width: 992px) {
  .lg-nav {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PopupData div {
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    margin-bottom: 5px;
  }

  .PopupData div p {
    margin-bottom: 0px;
  }

  .mobile-nav {
    display: none;
  }

  .mobile-nav-toggle {
    display: none;
  }

  .sidenav-lg::-webkit-scrollbar {
    width: 8px;
  }
  .sidenav-lg::-webkit-scrollbar-track {
    background: var(--FoxBlack);
  }
  .sidenav-lg::-webkit-scrollbar-thumb {
    background-color: var(--FoxYellow);
    border-radius: 6px;
  }

  .sidenav-lg {
    height: 100vh;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background: var(--Primary);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-right: 8px;
    overflow-y: scroll;
    width: 40vh;
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scroll-behavior: smooth;
    --scrollbarBG: rgb(0, 0, 0);
    --thumbBG: #004686;
  }

  .sidenav-lg .logoBg {
    background: white;
    border-radius: 0px 0px 12% 12%;
    padding: 10px;
    width: max-content;
    margin: auto;
    text-align: center;
  }

  .sidenav-lg hr {
    border-width: 2px;
    color: var(--White);
    width: 40%;
    margin: auto;
    opacity: 1;
  }

  .sidenav-lg-parent {
    position: relative; 
  }

  .outletDiv {
    position: absolute;
    left: 41vh;
    right: 2vh;
    top: 0vh;
  }
}

@media (max-width: 992px) {
  .outletDiv {
    position: absolute;
    left: 1vh;
    right: 1vh;
    top: 0vh;
  }

  nav {
    left: 0;
    right: 0;
  }
}

/* --- SideBar Accordion CSS for all sizes devices ----*/
.accordion{
  background-color: transparent !important;
}

.accordion .accordion-item{
  border: #000000;
  background: transparent !important;
}

.accordion .accrodion-body {
  background: var(--White) !important;
  box-shadow: none !important;
  border: none !important;
}

.accordion-item .accordion-header {
  background-color: transparent !important;
  border: none !important;
  padding: 0px;
  box-shadow: none;
  color: var(--White) !important;
  border: none !important;
}

.accordion .activeMenu .accordion-button {
  color: var(--Primary) !important;
  background: var(--White) !important;
  /* background: linear-gradient(45deg, var(--Primary), var(--Secondary)) !important; */
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  font-weight: 600 !important;
}


.accordion .newMenu .accordion-button:after{ 
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown{
  border-radius: 0px 0px 12px 0px !important;
}

.accordion .activeMenu.dropDown{
 background: var(--White) !important;
 border-radius: 0px 12px 12px 0px !important;

}

.accordion .activeMenu.dropDown .accordion-button{
  border-radius: 0px 12px 12px 0px !important;
}

.accordion .accordion-button {
  background: transparent !important;
  color: var(--White) !important;
  border: none !important;
  font-weight: 400 !important;
  border-radius: 0px 12px 12px 0px !important;
  box-shadow: none !important;
  padding: 14px 20px;
}

.accordion .accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  /* color: #FFFFFF !important; */
  /* background: linear-gradient(145deg, #1267cf, #073c7c) !important;
  color: #ffffff !important; */
}
/*
.accordion .accordion-button:not(.collapsed){
    background: linear-gradient(145deg, #1267cf, #073c7c) !important;
    color: #ffffff !important;
    font-weight: 500 !important;
} */

.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}
.accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: #000000 !important;
  border: none !important;
  /* background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%0ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important; */
}
.accordion .dropDown .accordion-button:after{ 
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .activeMenu.dropDown .accordion-button:after{ 
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23073c7c'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

/* .accordion .dropDown .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23073c7c'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

.accordion .dropDown .accordion-button.collapsed:after {
  box-shadow: none;
  outline: #000000 !important;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
} */

.accordion  label:nth-child(1){
  color: rgb(222, 219, 219) !important;
  padding: 1px 0px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0px 0px 0px 0px !important;

}

.accordion  label{
  color: rgb(222, 219, 219) !important;
  padding: 1px 20px !important;
  font-size: 14px;
  font-weight: 600;
  margin: 10px 0px 0px 0px;

}

.accordionNav{
  border: 1px solid var(--Primary) !important;
  color: var(--Primary) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 500 !important;
  background: transparent !important;
}

.accordionNav:hover{
  color: var(--White) !important;
  background: var(--Primary) !important;
}

.accordionNavActive{
  border: 1px solid transparent !important;
  color: var(--White) !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important; 
  font-weight: 500 !important;
  background: var(--Primary) !important;
}

/* ------------ Adding Artificial margin to every Oulets component's perent Div  ------------------- */

/* ------------ Adding Artificial margin to every Oulets component's perent Div  ------------------- */ 

  .outletPadding{
    margin-top: 10vh;
  }


/*  ---------------- Fixed header Responsive table CSS ---------------*/

.outer-wrapper {
  margin: 0px !important;
  padding: 0px !important;
  border-radius: 0px !important ;
  background: #ffffff !important;
  /* border: 1px solid rgb(224, 224, 224) !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15),
    inset 0 0 3px rgba(0, 0, 0, 0.2) !important; */
  max-width: 100% !important;
  max-height: fit-content !important;
}

.icon {
  transition: 0.3s !important;
  color: var(--Primary) !important;
}

.icon:hover {
  transform: translateY(-4px) scale(1.2) !important;
  cursor: pointer !important;
}

.table-wrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  /* height: fit-content; */
  margin-top: 5px;
  padding-bottom: 0px;
  padding: 0px !important;
}

.table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.table-wrapper::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px;
}

.table-wrapper::-webkit-scrollbar-thumb {
  background-color: #93c0ff;
  border-radius: 6px;
  border: 1px solid transparent;
}

.outer-wrapper table {
  width: 77vw;
  min-width: max-content;
  border-collapse: separate;
  border-spacing: 0px;
}

.outer-wrapper table th {
  z-index: 1 !important;
  position: sticky;
  top: 0px;
  background: hsl(212, 100%, 88%);
  color: var(--FoxBlack);
  text-align: left;
  font-weight: 700;
  font-size: 15px;
  outline: 0.7px solid transparent;
  border: 1.5px solid transparent;
}

.report-table table th{
  outline: 0.7px solid rgba(137, 137, 137, 0.1) !important;
  border: 1.5px solid rgba(137, 137, 137, 0.1) !important;
  text-align: center !important;
}

.report-table table td{
  text-align: center !important;
}

.outer-wrapper table th,
.outer-wrapper table td {
  padding: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.outer-wrapper table td {
  font-size: 15px;
  border: 0.5px solid rgba(137, 137, 137, 0.1);
  padding-left: 10px;
}

table tfoot {
  position: sticky;
  inset-block-end: 0;
  top: 0px;
}


/* -------------------- DashCards ----------------- */

.chartCard {
  text-align: center !important;
  padding: 30px 20px !important;
  border: none !important;
  border-radius: 12px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.refresh {
  transition: 0.3s;
  cursor: pointer;
}

.refresh:hover{
  transform: scale(1.29) !important;

}

.Home .headLabel,
.ViewContractors .headLabel {
  background: hsl(212, 100%, 88%);
  color: var(--Black);
  padding: 8px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 16px;
}

.Home .card,
.SurveyImages .card,
.ViewContractors .card {
  text-align: center;
  padding: 0px;
  border: none;
  border-radius: 8px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Home .card .data h6 {
  color: #494949 !important;
  text-align: center;
}

.SurveyImages .card {
  cursor: pointer;
}

.SurveyImages .card .card-img-wrapper {
  position: relative;
  overflow: hidden;
}

.SurveyImages .card .cardImg {
  border-radius: 8px 8px 0px 0px;
  transition: transform 0.3s ease-in-out;
}

.deleteCard {
  width: 60% !important ;
  background-color: #c74a4a !important;
  color: white !important;
  margin-left: 10 !important;
}

.SurveyImages .card .cardImg:hover {
  transform: scale(1.2);
}

.SurveyImages .card .card-body {
  padding: 8px;
}

.SurveyImages .card .card-body h6 {
  margin-bottom: 2px;
}

.SurveyImages .card .card-body p {
  margin-bottom: 0px;
  color: #929292;
  font-size: 14px;
}

/* .my-modal {
    padding: 0px !important;
    display: flex !important;
    justify-content: center !important;
  }
  
  .my-modal .modal-dialog {
    max-width: 100vw !important;
    margin: 0 !important;
  }
  
  .my-modal .modal-content {
    border-radius: 0;
    height: 82vh !important;
    width: 90vw !important;
  }

  .my-modal .modal-content .outer-wrapper table { 
    width: 70vw;
    border-collapse: separate;
    border-spacing: 0px;    
  }
  
  .my-modal .modal-content .outer-wrapper table th { 
    width: 230px; 
  }
  
  .my-modal .modal-content .outer-wrapper table td { 
    width: 600px; 
  } */

.Home .card div.card-body,
.ViewContractors .card div.card-body {
  display: flex !important;
  padding: 10px 16px;
  align-items: center;
  justify-content: center;
}

.Home .card div.card-body img,
.ViewContractors .card div.card-body img {
  width: 50px;
  height: 50px;
}

.Home .card .data,
.ViewContractors .card .data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px !important;
}

.Home .card h4,
.ViewContractors .card h4 {
  font-weight: 700;
  margin-bottom: 4px;
}

.Home .card h6,
.SurveyImages .card h6 {
  margin-bottom: 0px;
  font-size: 15px;
}

  .Home .card h6,
  .SurveyImages .card h6{
    margin-bottom: 0px;
    font-size: 15px;
  }

  .Home h3{
    font-weight: 600;
    margin-bottom: 0px;
  }

  .date-flex{
    display: flex;
    align-items: end;
  }

  .ViewSurvey .verifications .card{
    padding: 6px 12px;
    border: 1px solid var(--Primary);
    border-radius: 10px;
  }

  .ViewSurvey .verifications .card div{
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .ViewSurvey .verifications .card h6{
    display: flex;
    width: max-content;
    word-wrap: normal;
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 600;
  }

  .ViewSurvey .verifications .card p{
    margin-bottom: 0px;
    margin-left: 8px;
    font-size: 13px;
  }

  .ViewSurvey .verifications .card .role{
    position: absolute;
    right: 0;
    top: 0;
    background: var(--Primary);
    color: var(--White);
    padding: 2px 12px;
    font-size: 12px;
    border-radius: 0px 6px 0px 6px;
  }

/* 
  .ViewSurvey .card{
    padding: 20px;
    border: 1px solid #073c7c;
    border-radius: 12px;
  }

  .ViewSurvey .card .keys{
    width: max-content;
  }

  .ViewSurvey .card .value{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 10px;
  }

  .ViewSurvey .card .value h6{
    margin-bottom: 0px;
  }

  .ViewSurvey .card p{
    margin-bottom: 6px;
  } */


.ViewSurvey h4 {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ViewSurvey .outer-wrapper table th {
  position: relative;
  border: 0.5px solid rgba(137, 137, 137, 0.1);
  width: max-content !important;
  max-width: 35vh !important;
  /* display: flex;
    align-items: start;
    height: 100%; */
}

.ViewSurvey .outer-wrapper table th div {
  display: flex;
  justify-content: start;
  align-items: center;
}

.ViewSurvey .outer-wrapper table img {
  border-radius: 8px;
  margin-right: 16px;
}


/* -------------------- Survey Image Verifcation ------------------------ */
.SurveyImgVerification .imgCardParent {
  justify-content: center;
  overflow-y: scroll;
}

.SurveyImgVerification .imgCardParent::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.SurveyImgVerification .imgCardParent::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 0px;
}

.SurveyImgVerification .imgCardParent::-webkit-scrollbar-thumb {
  background-color: #93c0ff;
  border-radius: 6px;
  border: 1px solid transparent;
}

.SurveyImgVerification .card {
  text-align: center;
  padding: 0px;
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 16px 18px;
  margin-bottom: 16px;
  box-shadow: rgb(204, 219, 232) 2px 2px  6px 2px inset, 
  rgba(255, 255, 255, 0.578) -3px -3px  6px 1px inset;
  overflow: hidden;
}

.SurveyImgVerification .card .surveyId{
  position: absolute;
  top: 0;
  right: 0;
  color: var(--Black);
  background: rgb(225, 225, 225);
  border-radius: 0px 6px 0px 8px;
  padding: 4px 10px;
  margin: 0;

}

.SurveyImgVerification .card .index{
  position: absolute;
  top: 0;
  left: 0;
  color: var(--Black);
  background: rgb(225, 225, 225);
  border-radius: 8px 0px 8px 0px;
  padding: 4px 10px;
  margin: 0;
  font-weight: 600;
}

.SurveyImgVerification .card .RejectReason{
    /* position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0); */
    width: fit-content;
    text-align: center;
    color: var(--White);
    background: rgb(255, 9, 9);
    border-radius: 8px 8px 0px 0px;
    padding: 6px 12px;
    font-size: 13px !important;
    word-wrap: normal !important;
  /* font-weight: 600; */
}

.SurveyImgVerification .card.nothing{
  box-shadow: rgb(204, 219, 232) 2px 2px  6px 2px inset, 
                  rgba(255, 255, 255, 0.578) -3px -3px  6px 1px inset;
}

.SurveyImgVerification .card.rejected{
  border : 2px solid rgba(255, 0, 0, 0.817) !important;
}

.SurveyImgVerification .card.approved{
 border: 2px solid rgba(0, 142, 0, 0.854) !important;
}

.SurveyImgVerification .card p{
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #4c4c4c;
}

.SurveyImgVerification .card img,
.SurveyImgVerification .card video {
  border-radius: 8px;
  width: 100%;
  height: 100%;
}

.SurveyImgVerification .card img.approved {
  border:4px solid rgba(0, 142, 0, 0.854);
}

.SurveyImgVerification .card img.rejected {
  border: 4px solid rgba(255, 0, 0, 0.817);
}

.SurveyImgVerification .card video.approved {
  border:4px solid rgba(0, 142, 0, 0.854);
}

.SurveyImgVerification .card video.rejected {
  border: 4px solid rgba(255, 0, 0, 0.817);
}

.SurveyImgVerification .card img:hover{
  cursor: pointer;
}

    .SurveyImgVerification .card .imgBlock{
      position: relative !important;
      width: 120px;
      height: 120px;
      margin: 0 auto 17px auto;
    }

    .SurveyImgVerification .card .imgBlock .PlayBtn{
      position: absolute !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      border-radius: 8px 0px 8px 0px;
      transition: 0.1s;
    }

    .SurveyImgVerification .card .imgBlock .PlayBtn:hover {
      cursor: pointer;
      transform: translate(-50%, -50%) scale(1.2);
    }

    .SurveyImgVerification .card .imgBlock .Reject{
      position: absolute !important;
      bottom: 0;
      left: 0;
      background: rgba(255, 0, 0, 0.817) !important;
      color: white;
      padding: 4px 8px !important;
      border-radius: 0px 8px 0px 8px;
      transition: 0.2s;
    }

    .SurveyImgVerification .card .imgBlock .Reject:hover {
      cursor: pointer;
      padding: 8px 10px !important;
    }

    .SurveyImgVerification .card .imgBlock .Approve{
      position: absolute !important;
      bottom: 0;
      right: 0;
      background: rgba(0, 128, 0, 0.854) !important;
      color: white;
      padding: 4px 8px !important;
      border-radius: 8px 0px 8px 0px;
      transition: 0.2s
    }

    .SurveyImgVerification .card .imgBlock .Approve:hover {
      cursor: pointer;
      padding: 8px 10px !important;
    }

    .SurveyImgVerification .card .extraFields{
      display: flex;
      justify-content: center;
    }

    .SurveyImgVerification .card .extraFields h6{
      font-weight: 600;
      margin-bottom: 6px;
    }

    .SurveyImgVerification .card .extraFields svg{
       transition: 0.2s;
    }

    .SurveyImgVerification .card .extraFields svg:hover{
        cursor: pointer;
        transform: scale(1.29);
    }

    .SurveyImgVerification .card .extraFields > *:not(:last-child) {
      margin-right: 20px; 
    }

    .SurveyImgVerification .card .extraFields span{
      width: max-content;
    }


 /* ------------ Image Mutli Reject and Approval ------------------------ */
    .SurveyImgVerification .card .multiReject{
      background:  rgba(255, 0, 0, 0.817) !important;
      color: white;
      padding: 4px 8px !important;
      border-radius: 8px;
      transition: 0.2s;
    }

    .SurveyImgVerification .card .multiReject:hover{
      cursor: pointer;
      transform: scale(1.2);
    }

    .SurveyImgVerification .card .multiApprove{
      background: rgba(0, 128, 0, 0.854) !important;
      color: white;
      padding: 4px 8px !important;
      border-radius: 8px;
      transition: 0.2s
    }

    .SurveyImgVerification .card .multiApprove:hover{
      cursor: pointer;
      transform: scale(1.2);
    }
 
    .SurveyImgVerification .nav{
      width:max-content;
      border: none;
      border-radius: 8px;
      box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
      rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
    }

    .SurveyImgVerification .nav .nav-link{
      border-radius: 8px;
      padding: 5px 14px;
      font-weight: 500;
    }

    .SurveyImgVerification .nav .nav-link.Approved{
      color: #29a300;
    }

    .SurveyImgVerification .nav .nav-link.active.Approved{
      background: linear-gradient(145deg, #35d200, #29a300);
      color:var(--White);
      border: none;
      font-weight: 600;
    }

    .SurveyImgVerification .nav .nav-link.Rejected{
      color: #d70000;
    }

    .SurveyImgVerification .nav .nav-link.active.Rejected{
      background: linear-gradient(145deg, #f30000, #d70000);
      color:var(--White);
      border: none;
      font-weight: 600;
    }

    .SurveyImgVerification .ApproveToggle{
      background: linear-gradient(145deg, #35d200, #29a300);
      color:var(--White);
      border: none;
      font-weight: 600;
    }

    .SurveyImgVerification .RejectToggle{
      background: linear-gradient(145deg, #f30000, #d70000);
      color:var(--White);
      border: none;
      font-weight: 600;
    }

    .scan-pills .nav {
      border: none;
      border-radius: 12px;
      /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
      box-shadow: rgb(204, 219, 232) 2px 2px 6px 2px inset,
        rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
      width: max-content;
      margin-left: auto;
    }

    .scan-pills .nav .nav-link {
      border-radius: 12px;
      color: #073c7c;
      padding: 6px 12px;
    }

    .scan-pills .nav .nav-link.active {
      background: linear-gradient(145deg, #1267cf, #073c7c);
      padding: 6px 12px;
      border: none;
      border-radius: 12px;
      font-weight: 500;
    }


/* -------------------- Playstore Privacy Policy ------------------------ */
.PrivacyPolicy h3 {
  font-weight: 700;
  text-align: center;
}

.PrivacyPolicy ul li {
  margin-bottom: 6px;
}

.sum-row{
  background-color: #004686;
  color: white;
  font-size: larger;
}

.deal-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

hr{
  background-color: black;
  height: 2px; /* Adjust the thickness as needed */
  border: none;
}

.show-price{
  display: flex;
  justify-content: space-between;
  font-size: larger;
  font-weight: 600;
  margin: 0% 5%;
}

.final-price{
  padding: 5px;
  background-color: #004686;
  color: white;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 16px;
  padding: 16px;
}

.product-card {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin: 10px;
  text-align: center;
  overflow: hidden;
  transition: transform 0.2s;
}

.product-card:hover {
  transform: translateY(-5px);
}

.product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.product-info {
  padding: 16px;
}

.product-name {
  font-size: 18px;
  margin: 8px 0;
}

.price-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
}

.price {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.actual-amount {
  font-size: 16px;
  color: #999;
  text-decoration: line-through;
}

.discount {
  font-size: 16px;
  color: red;
  margin-top: 8px;
}

/* Mobile responsive */
@media (max-width: 600px) {
  .product-card {
    max-width: 100%;
    margin: 8px 0;
  }

  .product-list {
    flex-direction: column;
    align-items: center;
  }
}

.transaction-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.transaction-card {
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.image-container {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  overflow: hidden;
  border-radius: 10px;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-content {
  padding-left: 15px;
  flex-grow: 1
}


@media (max-width: 576px) {
  .transaction-card {
      max-width: 100%;
  }

}

.switch-camera-button {
  display: none !important; /* Hide by default */
}

@media (max-width: 768px) {
  .switch-camera-button {
    display: block !important; /* Show on mobile */
  }
}


